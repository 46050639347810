import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, Subject, catchError, filter, switchMap, take, throwError } from 'rxjs';
import { AuthService } from '../service/api/auth.service';
import { JwtService } from '../service/utils/jwt.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private _refreshSubject: Subject<any> = new Subject<any>();

  constructor(
    private authService: AuthService,
    private jwtService: JwtService,
    private router: Router,
    private logger: NGXLogger
    ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith(`${environment.loginApiUrl}/auth`)) {
      let headers = request.headers;

      if (!headers.has('Content-Type') && !(request.body instanceof FormData)) {
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');
      }

      headers = headers.set('APP_ID', environment.loginApiAppId);

      request = request.clone({
        headers: headers
      });

      if (
        request.url === `${environment.loginApiUrl}/auth/change-password`
        || request.url === `${environment.loginApiUrl}/auth/verify-2fa`
        || request.url === `${environment.loginApiUrl}/auth/send-verify-email`
      ) {
        request = this.setHeaders(request);
      }

    } else if (request.url.startsWith(`${environment.apiUrl}`)) {
      request = this.setHeaders(request);
    }

    return next.handle(request).pipe(
      catchError((error, caught) => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            if (error.error.message == 'JWT token expired') {
              if (request.url.endsWith('refresh-token')) {
                return throwError(() => error);
              }
              
              return this.handle401Error(request, next);
            } else if (error.error.message == 'JWT token decode error') {
              this.logger.info('Wrong JWT token');
              this.jwtService.removeAccessToken();
              this.jwtService.removeRefreshToken();
              this.router.navigate(['auth/login']);
            }
          } 

          return throwError(() => error);
      })
    );
  }

  private handle401Error(request: HttpRequest<unknown>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this._refreshSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap(response => {
          this.logger.info('Refresh token success');
          this.isRefreshing = false;
          this.jwtService.setAccessToken(response.data.access_token),
          this.jwtService.setRefreshToken(response.data.refresh_token)

          this._refreshSubject.next(response.data.access_token);

          request = this.setHeaders(request);
          
          return next.handle(request);
        }),
        catchError(error => {
          this.isRefreshing = false;
          if (error.status == 401) {
            this.logger.info('Refresh token expired');
            this.jwtService.removeAccessToken();
            this.jwtService.removeRefreshToken();
            this.router.navigate(['auth/login']);
          }
          return throwError(() => error);
        })
      );
    } else {
      return this._refreshSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(() => {
          request = this.setHeaders(request);

          return next.handle(request);
        })
      );
    }
  }

  private setHeaders(request: HttpRequest<unknown>): HttpRequest<unknown> {
    let headers = request.headers;

    if (!headers.has('Content-Type') && !(request.body instanceof FormData)) {
      headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    }

    let accessToken = this.jwtService.getAccessToken();

    if (accessToken !== null) {
      headers = headers.set('Authorization', `Bearer ${accessToken}`);
    }

    request = request.clone({
      headers: headers
    });

    return request;
  }
}

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
];
