import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { JwtService } from '../service/utils/jwt.service';
import { NGXLogger } from 'ngx-logger';
import { User } from '../model/user.model';
import { ProfileService } from '../service/api/profile.service';
import { filter, lastValueFrom } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AppEventType, EventQueueService } from '../service/utils/event-queue.service';
import { OrganizationService } from '../service/api/organization.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Organization } from '../model/organization.model';

export interface OrganizationLogo {
    imageFile?: string,
    loading: boolean
}

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    providers: [],
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];
    user?: User | null;
    showHeader: boolean = false;
    organization?: Organization | null;
    organizationLogo?: OrganizationLogo | null;

    constructor(
        public layoutService: LayoutService,
        private jwtService: JwtService,
        private eventQueueService: EventQueueService,
        private profileService: ProfileService,
        private organizationService: OrganizationService,
        private router: Router,
        private route: ActivatedRoute,
        private logger: NGXLogger,
        public translate: TranslateService
    ) {}

    ngOnInit() {
        this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
            this.buildMenu();
        });

        // Register for organization update event to change organization name or organization logo
        this.eventQueueService.on(AppEventType.OrganizationUpdate).subscribe({
            next: (event) => {
                if (event.payload.id === this.user?.organizations?.[0]?.id) {
                    this.loadUser();
                }
            }
        });

        // Subscribe for route changes to change menu accordingly
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe({
                next: (event: any) => {
                    this.buildMenu();
                }
        });

        this.logger.debug('User role: ' + this.jwtService.getUserRole());
        this.loadUser();
    }

    showBackButton() {
        const role = this.jwtService.getUserRole();
        return role != null && ['alpha-admin', 'cluster-admin'].includes(role!) && this.organization != null;
    }

    async loadUser() {
        try {
            const userResponse = await lastValueFrom(this.profileService.profile());
            this.user = userResponse.data;
            this.buildMenu();
        } catch(e) {
            if (e instanceof HttpErrorResponse) {

            }
            this.logger.error(`${this.constructor.name} ERROR retrieving user profile: ` + JSON.stringify(e));
        }
    }

    async loadOrganization(organizationId: string) {
        if (organizationId != null) {
            try {
                const organizationResponse = await lastValueFrom(this.organizationService.get(organizationId));
                this.organization = organizationResponse.data;
                this.loadOrganizationLogo();
            } catch(e) {
                if (e instanceof HttpErrorResponse) {
        
                }
                this.logger.error(`[${this.constructor.name}] Error retriving organization ` + JSON.stringify(e));
            }
        }
    }

    async loadOrganizationLogo() {
        let role = this.jwtService.getUserRole();

        if (this.organization != null) {
            this.organizationLogo = {
                imageFile: undefined,
                loading: true
            };
            try {
                if (this.organization != null) {
                    const organizationLogoResponse = await lastValueFrom(this.organizationService.getLogo(this.organization.id!));
                    this.organizationLogo.imageFile = URL.createObjectURL(organizationLogoResponse);
                }
            } catch (e) {
                if (e instanceof HttpErrorResponse) {  
                }
                this.logger.error(`${this.constructor.name} Error getting organization image: ` + JSON.stringify(e));
            }
            this.organizationLogo.loading = false;
        }
    }

    buildMenu() {
        let role = this.jwtService.getUserRole();
        if (role === 'alpha-admin') {
            this.buildAlphaAdminMenu();
        } else if(role === 'cluster-admin') {
            this.buildClusterAdminMenu();
        } else if(role === 'organization-admin') {
            this.buildOrganizationAdminMenu();
        } else if (role === 'organization-user') {
            this.buildOrganizationUserMenu();
        }
    }

    async buildAlphaAdminMenu() {
        let childRoute = this.route;

        let organizationId = null;

        while (childRoute.firstChild) {
            childRoute = childRoute.firstChild;
            if (childRoute.snapshot.paramMap.get('organizationId') != null) {
                organizationId = childRoute.snapshot.paramMap.get('organizationId');
                break;
            }
        }
        
        if (organizationId != null) {
            if (organizationId != this.organization?.id) {
                await this.loadOrganization(organizationId);
                this.buildAlphaAdminOrganizationMenu();
            }
        } else {
            this.organization = null;
            if (this.organizationLogo?.imageFile != null) {
                URL.revokeObjectURL(this.organizationLogo.imageFile);
            }
            this.organizationLogo = null;
            this.buildAlphaAdminMainMenu();
        }
    }

    buildAlphaAdminMainMenu() {
        this.showHeader = false;
        this.model = [
            {
                label: this.translate.instant('LABELS.home'),
                items: [
                    { label: this.translate.instant('LABELS.dashboard'), icon: 'pi pi-fw pi-th-large', routerLink: ['/'] }
                ]
            },
            {
                label: this.translate.instant('LABELS.manage'),
                items: [
                    { label: this.translate.instant('LABELS.users'), icon: 'pi pi-fw pi-users', routerLink: ['/users'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                    { label: this.translate.instant('LABELS.clusters'), icon: 'pi pi-fw pi-server', routerLink: ['/clusters'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                    { label: this.translate.instant('LABELS.organizations'), icon: 'pi pi-fw pi-sitemap', routerLink: ['/organizations'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } }
                ]
            },
            {
                label: this.translate.instant('LABELS.compliance'),
                items: [
                    { label: this.translate.instant('LABELS.security-standards'), icon: 'pi pi-fw pi-list', routerLink: ['security-standards'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } }
                ]
            }
        ];
    }

    buildAlphaAdminOrganizationMenu() {
        this.showHeader = true;
        if (this.organization != null) {
            this.model = [
                {
                    label: this.translate.instant('LABELS.home'),
                    items: [
                        { label: this.translate.instant('LABELS.organization-admin-dashboard'), icon: 'pi pi-fw pi-th-large', routerLink: ['organizations', this.organization.id, 'detail'] }
                    ]
                },
                {
                    label: this.translate.instant('LABELS.manage'),
                    items: [
                        { label: this.translate.instant('LABELS.users'), icon: 'pi pi-users', routerLink: ['organizations', this.organization.id, 'users'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                        { label: this.translate.instant('LABELS.assessments'), icon: 'pi pi-fw pi-shield', routerLink: ['organizations', this.organization.id, 'assessments'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                        { label: this.translate.instant('LABELS.assets'), icon: 'pi pi-fw pi-server', routerLink: ['organizations', this.organization.id, 'assets'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                        { label: this.translate.instant('LABELS.organizational-units'), icon: 'pi pi-fw pi-sitemap', routerLink: ['organizations', this.organization.id, 'organizational-units'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                        { label: this.translate.instant('LABELS.employees'), icon: 'pi pi-fw pi-users', routerLink: ['organizations', this.organization.id, 'employees'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                        { label: this.translate.instant('LABELS.job-titles'), icon: 'pi pi-fw pi-tags', routerLink: ['organizations', this.organization.id, 'job-titles'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                        { label: this.translate.instant('LABELS.business-processes'), icon: 'pi pi-fw pi-book', routerLink: ['organizations', this.organization.id, 'business-processes'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                        { label: this.translate.instant('LABELS.documents'), icon: 'pi pi-fw pi-file', routerLink: ['organizations', this.organization.id, 'documents'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                        { label: this.translate.instant('LABELS.risk-scale-templates'), icon: 'pi pi-fw pi-arrows-v', routerLink: ['organizations', this.organization.id, 'risk-scale-template'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } }
                    ]
                }
            ];
        }
    }

    async buildClusterAdminMenu() {
        let childRoute = this.route;

        let organizationId = null;

        while (childRoute.firstChild) {
            childRoute = childRoute.firstChild;
            if (childRoute.snapshot.paramMap.get('organizationId') != null) {
                organizationId = childRoute.snapshot.paramMap.get('organizationId');
                break;
            }
        }
        
        if (organizationId != null) {
            if (organizationId != this.organization?.id) {
                await this.loadOrganization(organizationId);
                this.buildClusterAdminOrganizationMenu();
            }
        } else {
            this.organization = null;
            if (this.organizationLogo?.imageFile != null) {
                URL.revokeObjectURL(this.organizationLogo.imageFile);
            }
            this.organizationLogo = null;
            this.buildClusterAdminMainMenu();
        }
    }

    buildClusterAdminMainMenu() {
        this.showHeader = true;
        const clusterId = this.user?.cluster?.id;

        this.model = [
            {
                label: this.translate.instant('LABELS.home'),
                items: [
                    { label: this.translate.instant('LABELS.cluster-admin-dashboard'), icon: 'pi pi-fw pi-th-large', routerLink: ['clusters', clusterId, 'detail'] }
                ]
            },
            {
                label: this.translate.instant('LABELS.manage'),
                items: [
                    { label: this.translate.instant('LABELS.users'), icon: 'pi pi-fw pi-users', routerLink: ['clusters', clusterId, 'users'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                    { label: this.translate.instant('LABELS.organizations'), icon: 'pi pi-fw pi-sitemap', routerLink: ['clusters', clusterId, 'organizations'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } }
                ]
            },
            {
                label: this.translate.instant('LABELS.compliance'),
                items: [
                    { label: this.translate.instant('LABELS.security-standards'), icon: 'pi pi-fw pi-list', routerLink: ['security-standards'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } }
                ]
            }
        ];
    }

    buildClusterAdminOrganizationMenu() {
        this.showHeader = true;
        if (this.organization != null) {
            this.model = [
                {
                    label: this.translate.instant('LABELS.home'),
                    items: [
                        { label: this.translate.instant('LABELS.organization-admin-dashboard'), icon: 'pi pi-fw pi-th-large', routerLink: ['organizations', this.organization.id, 'detail'] }
                    ]
                },
                {
                    label: this.translate.instant('LABELS.manage'),
                    items: [
                        { label: this.translate.instant('LABELS.users'), icon: 'pi pi-users', routerLink: ['organizations', this.organization.id, 'users'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                        { label: this.translate.instant('LABELS.assessments'), icon: 'pi pi-fw pi-shield', routerLink: ['organizations', this.organization.id, 'assessments'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                        { label: this.translate.instant('LABELS.assets'), icon: 'pi pi-fw pi-server', routerLink: ['organizations', this.organization.id, 'assets'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                        { label: this.translate.instant('LABELS.organizational-units'), icon: 'pi pi-fw pi-sitemap', routerLink: ['organizations', this.organization.id, 'organizational-units'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                        { label: this.translate.instant('LABELS.employees'), icon: 'pi pi-fw pi-users', routerLink: ['organizations', this.organization.id, 'employees'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                        { label: this.translate.instant('LABELS.job-titles'), icon: 'pi pi-fw pi-tags', routerLink: ['organizations', this.organization.id, 'job-titles'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                        { label: this.translate.instant('LABELS.business-processes'), icon: 'pi pi-fw pi-book', routerLink: ['organizations', this.organization.id, 'business-processes'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                        { label: this.translate.instant('LABELS.documents'), icon: 'pi pi-fw pi-file', routerLink: ['organizations', this.organization.id, 'documents'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                        { label: this.translate.instant('LABELS.risk-scale-templates'), icon: 'pi pi-fw pi-arrows-v', routerLink: ['organizations', this.organization.id, 'risk-scale-template'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } }
                    ]
                }
            ];
        }
    }

    async buildOrganizationAdminMenu() {
        this.showHeader = true;
        const organizationId = this.user?.organizations?.[0].id;
        if (organizationId != null) {
            await this.loadOrganization(organizationId);
        };

        this.model = [
            {
                label: this.translate.instant('LABELS.home'),
                items: [
                    { label: this.translate.instant('LABELS.organization-admin-dashboard'), icon: 'pi pi-fw pi-th-large', routerLink: ['organizations', organizationId, 'detail'] }
                ]
            },
            {
                label: this.translate.instant('LABELS.manage'),
                items: [
                    { label: this.translate.instant('LABELS.users'), icon: 'pi pi-users', routerLink: ['organizations', organizationId, 'users'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                    { label: this.translate.instant('LABELS.assessments'), icon: 'pi pi-fw pi-shield', routerLink: ['organizations', organizationId, 'assessments'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                    { label: this.translate.instant('LABELS.assets'), icon: 'pi pi-fw pi-server', routerLink: ['organizations', organizationId, 'assets'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                    { label: this.translate.instant('LABELS.organizational-units'), icon: 'pi pi-fw pi-sitemap', routerLink: ['organizations', organizationId, 'organizational-units'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                    { label: this.translate.instant('LABELS.employees'), icon: 'pi pi-fw pi-users', routerLink: ['organizations', organizationId, 'employees'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                    { label: this.translate.instant('LABELS.job-titles'), icon: 'pi pi-fw pi-tags', routerLink: ['organizations', organizationId, 'job-titles'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                    { label: this.translate.instant('LABELS.business-processes'), icon: 'pi pi-fw pi-book', routerLink: ['organizations', organizationId, 'business-processes'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                    { label: this.translate.instant('LABELS.documents'), icon: 'pi pi-fw pi-file', routerLink: ['organizations', organizationId, 'documents'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                    { label: this.translate.instant('LABELS.risk-scale-templates'), icon: 'pi pi-fw pi-arrows-v', routerLink: ['organizations', organizationId, 'risk-scale-template'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } }
                ]
            },
            {
                label: this.translate.instant('LABELS.compliance'),
                items: [
                    { label: this.translate.instant('LABELS.security-standards'), icon: 'pi pi-fw pi-list', routerLink: ['security-standards'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } }
                ]
            }
        ];
    }

    async buildOrganizationUserMenu() {
        this.showHeader = true;
        const organizationId = this.user?.organizations?.[0].id;
        if (organizationId != null) {
            await this.loadOrganization(organizationId);
        };

        this.model = [
            {
                label: this.translate.instant('LABELS.home'),
                items: [
                    { label: this.translate.instant('LABELS.organization-user-dashboard'), icon: 'pi pi-fw pi-th-large', routerLink: ['organizations', organizationId, 'detail'] }
                ]
            },
            {
                label: this.translate.instant('LABELS.manage'),
                items: [
                    { label: this.translate.instant('LABELS.users'), icon: 'pi pi-users', routerLink: ['organizations', organizationId, 'users'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                    { label: this.translate.instant('LABELS.assessments'), icon: 'pi pi-fw pi-shield', routerLink: ['organizations', organizationId, 'assessments'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                    { label: this.translate.instant('LABELS.assets'), icon: 'pi pi-fw pi-server', routerLink: ['organizations', organizationId, 'assets'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                    { label: this.translate.instant('LABELS.organizational-units'), icon: 'pi pi-fw pi-sitemap', routerLink: ['organizations', organizationId, 'organizational-units'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                    { label: this.translate.instant('LABELS.employees'), icon: 'pi pi-fw pi-users', routerLink: ['organizations', organizationId, 'employees'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                    { label: this.translate.instant('LABELS.job-titles'), icon: 'pi pi-fw pi-tags', routerLink: ['organizations', organizationId, 'job-titles'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                    { label: this.translate.instant('LABELS.business-processes'), icon: 'pi pi-fw pi-book', routerLink: ['organizations', organizationId, 'business-processes'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
                    { label: this.translate.instant('LABELS.documents'), icon: 'pi pi-fw pi-file', routerLink: ['organizations', organizationId, 'documents'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } }
                ]
            },
            {
                label: this.translate.instant('LABELS.compliance'),
                items: [
                    { label: this.translate.instant('LABELS.security-standards'), icon: 'pi pi-fw pi-list', routerLink: ['security-standards'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } }
                ]
            }
        ];
    }

    backToMainMenu() {
        if (this.user?.role_name === 'alpha-admin') {
            this.router.navigate(['/organizations']);
        } else if (this.user?.role_name === 'cluster-admin') {
            this.router.navigate(['clusters', this.user?.cluster?.id, 'organizations']);
        }
        
    }
}


