import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '../service/utils/toast.service';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable()
export class RateLimiterInterceptor implements HttpInterceptor {

  private lastMessageTime: number = 0;

  constructor(private toastService: ToastService) {} // Inject ToastrService

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        // Check if the error status is 429 (Too Many Requests)
        if (error.status === 429) {
          this.showTooManyRequestsToast();
        }

        // Handle other errors or rethrow
        return throwError(() => error);
      })
    );
  }

  // Method to show toast message
  private showTooManyRequestsToast() {
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - this.lastMessageTime;

    if (timeDifference >= 3000) {
      this.toastService.showWarning('You have made too many requests. Please try again later.', 'Too Many Requests');
    }
  }
}
