import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom } from 'rxjs';
import { User } from 'src/app/model/user.model';
import { AuthService } from 'src/app/service/api/auth.service';
import { ProfileService } from 'src/app/service/api/profile.service';
import { ToastService } from 'src/app/service/utils/toast.service';

enum TwoFactoAuthenticationPage {
  edit2fa = 1,
  verify2faEmail,
  verify2faTotp
}

@Component({
  selector: 'app-edit-two-factor-authentication',
  templateUrl: './edit-two-factor-authentication.component.html',
  styleUrl: './edit-two-factor-authentication.component.scss'
})
export class EditTwoFactorAuthenticationComponent {
  
  @Input() user?: User | null = null;
  onSave = output<User | null>();
  onCancel = output<void>();
  
  TwoFactoAuthenticationPage = TwoFactoAuthenticationPage;
  currentPage = TwoFactoAuthenticationPage.edit2fa;
  loading: boolean = false;
  enable2FAForm: FormGroup;
  verify2FAEmailForm: FormGroup;
  verify2FATOTPForm: FormGroup;
  totpSecret?: string;
  qrCodeTOTP?: string;
  ref: DynamicDialogRef | undefined;

  constructor(
    private userProfileService: ProfileService,
    private authService: AuthService,
    private toastService: ToastService,
    private messageService: MessageService,
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private dialogService: DialogService,
    private logger: NGXLogger,
    private translate: TranslateService
  ) {
  
    this.enable2FAForm = new FormGroup(
      { type: new FormControl<string | null>(null, []) }
    );
    this.enable2FAForm.setValue({ type: 'email' });

    this.verify2FAEmailForm = new FormGroup(
      { code: new FormControl<string | null>(null, [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(6)
        ])
      }
    );

    this.verify2FATOTPForm = new FormGroup(
      { code: new FormControl<string | null>(null, [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(6)]
        )
      }
    );
  }


  get enable2FAType() {
    return this.enable2FAForm.get('type');
  }

  get verify2FATOTPCode() {
    return this.verify2FATOTPForm.get('code');
  }

  get verify2FAEmailCode() {
    return this.verify2FAEmailForm.get('code');
  }

  handleEnterKey(originForm: FormGroup) {
    if (originForm == this.enable2FAForm ) {
      if (this.enable2FAForm.valid) {
        this.enable2FA();
      } else {
        this.enable2FAForm.markAllAsTouched();
        this.enable2FAForm.markAsDirty();
        this.enable2FAForm.markAsTouched();
      }
    } else if (originForm == this.verify2FAEmailForm) {
      if (this.verify2FAEmailForm.valid) {
        this.verify2FA();
      } else {
        this.verify2FAEmailForm.markAllAsTouched();
        this.verify2FAEmailForm.markAsDirty();
        this.verify2FAEmailForm.markAsTouched();
      }
    } else if (originForm == this.verify2FATOTPForm) {
      if (this.verify2FATOTPForm.valid) {
        this.verify2FA();
      } else {
        this.verify2FATOTPForm.markAllAsTouched();
        this.verify2FATOTPForm.markAsDirty();
        this.verify2FATOTPForm.markAsTouched();
      }
    }
  }

  async enable2FA() {
    this.loading = true;
    try {
      const twoFactorAutenticationResponse = await lastValueFrom(this.userProfileService.enable2FA(this.enable2FAType?.value));
      this.user = twoFactorAutenticationResponse.data;      
      if (this.user?.mfa_type == 'email') {
        this.showVerify2FAEmail();
      } else if (this.user?.mfa_type == 'totp') {
        this.showVerify2FATOTP();
      }
    } catch (e) {
      this.messageService.clear();
      if (e instanceof HttpErrorResponse) {
        if (e.status === 401) {
          this.messageService.add({severity: 'error', summary: this.translate.instant('ERRORS.unauthorized')});
        } else if (e.status === 422) {
          this.messageService.add({severity: 'error', summary: this.translate.instant('ERRORS.input-validation-error')});
        }
        this.logger.error('[EditUserProfile] Error enabling two factor authentication: ' + JSON.stringify(e));
      }
    }
    this.loading = false;
  }

  showEnable2FA() {
    this.resetVerificationForms();
    this.currentPage = TwoFactoAuthenticationPage.edit2fa;
  }

  showVerify2FAEmail() {
   this.currentPage = TwoFactoAuthenticationPage.verify2faEmail;
  }

  showVerify2FATOTP() {
    this.getQRCode();
    this.getTOTPSecret();
    this.currentPage = TwoFactoAuthenticationPage.verify2faTotp;
  }

  async verify2FA() {
    let code;
    if (this.currentPage == TwoFactoAuthenticationPage.verify2faEmail) {
      code = this.verify2FAEmailCode?.value;
    } else if (this.currentPage == TwoFactoAuthenticationPage.verify2faTotp) {
      code = this.verify2FATOTPCode?.value;
    }
    this.loading = true;
    try {
      if (this.user != null) {
        const verifyTwoFactorAuthenticationResponse = await lastValueFrom(this.authService.verify2FA(code));
        this.user.mfa_verified = true;
        this.onSave.emit(this.user);
        this.toastService.showSuccess(this.translate.instant('MESSAGES.2fa-enabled'));
      }
    } catch (e) { 
      this.messageService.clear();
      if (e instanceof HttpErrorResponse) {
        if (e.status === 401) {
          this.messageService.add({severity: 'error', summary: this.translate.instant('ERRORS.unauthorized')});
        } else if (e.status === 422) {
          this.messageService.add({severity: 'error', summary: this.translate.instant('ERRORS.input-validation-error')});
        }
        this.logger.error('[EditUserProfile] Error verifying two factor authentication: ' + JSON.stringify(e));
      }
    }
    this.loading = false;
  }

  back() {
    this.resetForms();
    this.onCancel.emit();
  }

  resetForms() {
    this.enable2FAForm.reset();
    this.enable2FAForm.setValue({ type: 'email' });
    this.resetVerificationForms();
  }

  resetVerificationForms() {
    this.verify2FAEmailForm.reset();
    this.verify2FATOTPForm.reset();
    this.totpSecret = undefined;
    if (this.qrCodeTOTP) {
      URL.revokeObjectURL(this.qrCodeTOTP);
      this.qrCodeTOTP = undefined;
    }
  }

  async getQRCode() {
    this.loading = true;
    if (this.qrCodeTOTP) {
      URL.revokeObjectURL(this.qrCodeTOTP);
      this.qrCodeTOTP = undefined;
    }
    try {
      const qrCodeResponse = await lastValueFrom(this.userProfileService.getQRCodeTOTP());
      this.qrCodeTOTP = URL.createObjectURL(qrCodeResponse);
    } catch (e) { 
      this.messageService.clear();
      if (e instanceof HttpErrorResponse) {
        if (e.status === 401) {
          this.messageService.add({severity: 'error', summary: this.translate.instant('ERRORS.unauthorized')});
        } else if (e.status === 422) {
          this.messageService.add({severity: 'error', summary: this.translate.instant('ERRORS.input-validation-error')});
        }
        this.logger.error(`[${this.constructor.name}] Error getting qrcode image: ` + JSON.stringify(e));
      }
    }
    this.loading = false;
  }


  async getTOTPSecret() {
    this.loading = true;
    try {
      const totpSecretResponse = await lastValueFrom(this.userProfileService.getTOTPSecret());
      this.totpSecret = totpSecretResponse.data.totp_secret;
    } catch (e) { 
      this.messageService.clear();
      if (e instanceof HttpErrorResponse) {
        if (e.status === 401) {
          this.messageService.add({severity: 'error', summary: this.translate.instant('ERRORS.unauthorized')});
        } else if (e.status === 422) {
          this.messageService.add({severity: 'error', summary: this.translate.instant('ERRORS.input-validation-error')});
        }
        this.logger.error(`[${this.constructor.name}] Error getting totp secret: ` + JSON.stringify(e));
      }
    }
    this.loading = false;
  }

}