<div *ngIf="currentPage === UserProfilePage.Home" id="profile-container">

    <div class="relative flex flex-column gap-4 md:flex-row">
        <div *ngIf="loading" class="absolute top-0 left-0 w-full h-full bg-white-alpha-60 z-5">
            <div class="flex align-items-center w-full h-full">
                <p-progressSpinner class="m-auto" styleClass="w-4rem h-4rem alpha-spinner block" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
            </div>
        </div>
        

        <div class="flex flex-column gap-4 col-12 md:col-8 border-1 border-200 border-round">
            <span class="text-xl font-semibold">{{'LABELS.details' | translate}}</span>
            <div class="flex flex-column gap-1">
                <span class="text-base font-light text-color-secondary">ID</span>
                <span class="text-xl font-normal">{{ user?.id }}</span>
            </div>
            
            <div class="flex flex-column gap-1">
                <span class="text-base font-light text-color-secondary">{{'LABELS.email' | translate}}</span>
                <span class="text-xl font-normal">{{ user?.email }}</span>
            </div>
            <div class="flex flex-column gap-1">
                <span class="text-base font-light text-color-secondary">{{'LABELS.role' | translate}}</span>
                <p-tag value="{{'ROLES.' + user?.role_name | translate}}" severity="info" class="uppercase" styleClass="text-lg font-normal"></p-tag>
            </div>
            <div class="flex flex-column gap-1">
                <span class="text-base font-light text-color-secondary">{{'LABELS.name' | translate }}</span>
                <span class="text-xl font-normal">{{ user?.name }}</span>
            </div>
            <div class="flex flex-column gap-1">
                <span class="text-base font-light text-color-secondary">{{'LABELS.lastname' | translate}}</span>
                <span class="text-xl font-normal">{{ user?.last_name }}</span>
            </div>
            <div class="flex flex-column md:flex-row gap-4">
                <p-button icon="pi pi-user-edit" label="{{'LABELS.edit' | translate}}" [outlined]="true" (onClick)="showEditPage()" styleClass="w-full md:w-auto"></p-button>
                <p-button icon="pi pi-image" label="{{'LABELS.edit-profile-picture' | translate}}" [outlined]="true" (onClick)="showEditProfilePicture()" styleClass="w-full md:w-auto"></p-button>
            </div>
        </div>
        <div class="flex flex-column gap-4 col-12 md:col-4 p-0 md:pr-4">
            <div class="border-1 border-200 border-round p-3 flex flex-column gap-4">
                <span class="text-xl font-semibold">{{'LABELS.email' | translate}}</span>
                <div class="flex flex-column gap-1">
                    <span class="text-base font-light text-color-secondary">{{'LABELS.verification-status' | translate}}</span>
                    <p-tag *ngIf="user?.email_verified" icon="pi pi-check" severity="success" value="{{'LABELS.verified' | translate}}" class="inline md:block uppercase ml-3 md:ml-0 mt-2"></p-tag>
                    <p-tag *ngIf="!user?.email_verified" icon="pi pi-times" severity="warning" value="{{'LABELS.not-verified' | translate}}" class="inline md:block uppercase ml-3 md:ml-0 mt-2"></p-tag>
                </div>
                <p-button *ngIf="!user?.email_verified" label="Resend verification email" [outlined]="true" styleClass="w-full" (onClick)="sendEmailVerificationMessage()"></p-button>
            </div>
            <div class="border-1 border-200 border-round p-3 flex flex-column gap-4">
                <span class="text-xl font-semibold">{{'LABELS.password' | translate}}</span>
                <div class="flex flex-column gap-1">
                    <p-button label="{{'LABELS.change-password' | translate}}" [outlined]="true" icon="pi pi-key" (onClick)="showChangePassword()" styleClass="w-full"></p-button>
                </div>
            </div>
            <div class="border-1 border-200 border-round p-3 flex flex-column gap-4">
                <span class="text-xl font-semibold">{{'LABELS.two-factor-authentication' | translate}}</span>
                <div class="flex flex-column md:flex-row gap-3">
                    <div>
                        <span class="text-base font-light text-color-secondary">{{'LABELS.status' | translate}}</span>
                        <p-tag *ngIf="user?.mfa_enabled && user?.mfa_verified" icon="pi pi-check" severity="success" value="{{'LABELS.enabled' | translate}}" class="inline md:block uppercase ml-3 md:ml-0 mt-2"></p-tag>
                        <p-tag [style]="{ 'background': 'var(--gray-400)'}" *ngIf="!user?.mfa_enabled || !user?.mfa_verified" icon="pi pi-times" value="{{'LABELS.disabled' | translate}}" class="inline md:block uppercase ml-3 md:ml-0 mt-2"></p-tag>
                    </div>
                    <div>
                        <span *ngIf="user?.mfa_enabled && user?.mfa_verified" class="text-base font-light text-color-secondary">{{'LABELS.method' | translate}}</span>
                        <p-tag *ngIf="user?.mfa_enabled && user?.mfa_verified && user?.mfa_type=='email'" icon="pi pi-envelope" value="{{'LABELS.email' | translate}}" severity="info" class="inline md:block uppercase ml-3 md:ml-0 mt-2"></p-tag>
                        <p-tag *ngIf="user?.mfa_enabled && user?.mfa_verified && user?.mfa_type=='totp'" icon="pi pi-calculator" value="{{'LABELS.totp' | translate}}" severity="info" class="inline md:block uppercase ml-3 md:ml-0 mt-2"></p-tag>
                    </div>
                </div>
                <p-button *ngIf="user?.mfa_enabled && user?.mfa_verified && user?.mfa_type=='totp'" label="{{'LABELS.show-qr-code' | translate}}" [outlined]="true" icon="pi pi-qrcode" (onClick)="showQRCode()" styleClass="w-full"></p-button>
                <p-button *ngIf="user?.mfa_enabled && user?.mfa_verified" label="{{'LABELS.change-method' | translate}}" [outlined]="true" (onClick)="showChange2FAType()" styleClass="w-full"></p-button>
                <p-button *ngIf="!user?.mfa_enabled || !user?.mfa_verified" label="{{'LABELS.enable' | translate}}" [outlined]="true" (onClick)="showEnable2FA()" styleClass="w-full"></p-button>
                <p-button *ngIf="user?.mfa_enabled && user?.mfa_verified" label="{{'LABELS.disable' | translate}}" [outlined]="true" (onClick)="disable2FA()" styleClass="w-full"></p-button>
            </div>
        </div>
        <p-confirmDialog></p-confirmDialog>
    </div>

    <div class="w-full">
        <p-messages></p-messages>
    </div>

    <div class="flex flex-row-reverse gap-2 md:mt-4">
        <p-button label="{{'LABELS.logout' | translate}}" [outlined]="true" severity="danger" (onClick)="logout()"></p-button>
    </div>
</div>


<!-- Edit User Details -->


<div *ngIf="currentPage === UserProfilePage.Edit" id="edit-details-container">
    <app-edit-user-details 
        [user]="user"
        (onSave)="onEditProfileSave($event)"
        (onCancel)="onEditProfileCancel()"
    ></app-edit-user-details>
</div>

<!-- Edit Profile Picture -->
<div *ngIf="currentPage === UserProfilePage.EditProfilePicture" id="edit-profile-picture-container">
    <app-edit-profile-picture
        [user]="user"
        (onSave)="onProfilePictureSave($event)"
        (onCancel)="onEditProfileCancel()"
    ></app-edit-profile-picture>
</div> 

<!-- Change Password -->
<div *ngIf="currentPage === UserProfilePage.ChangePassword" id="change-password-container">
    <app-change-password
        [user]="user"
        (onSave)="onPasswordChangeSave()"
        (onCancel)="onEdit2FACancel()"
    ></app-change-password>
</div> 

<!-- Enable 2FA or Change2FA Type-->
<div *ngIf="currentPage === UserProfilePage.Enable2FA || currentPage === UserProfilePage.Change2FAType" id="enable-2fa-container">
    <app-edit-two-factor-authentication
        [user]="user"
        (onSave)="onEdit2FASave($event)"
        (onCancel)="onEdit2FACancel()"
    ></app-edit-two-factor-authentication>
</div>

<!-- Verify TOTP 2FA QR Code-->
 <div *ngIf="currentPage === UserProfilePage.ShowTOTPQRCode" id="show-2fa-totp-qr-container">
    <app-qrcode (onCancel)="onQrCodeCancel()"></app-qrcode>
</div> 
