<div class="relative">
    <div *ngIf="loading" class="absolute top-0 left-0 w-full h-full bg-white-alpha-60 z-5">
        <div class="flex align-items-center w-full h-full">
            <p-progressSpinner class="m-auto" styleClass="w-4rem h-4rem alpha-spinner block" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
        </div>
    </div>

    <span class="text-xl font-semibold">{{'LABELS.edit-user-details' | translate}}</span>
    <p-divider></p-divider>
    <div class="mt-5" pFocusTrap>
        <form [formGroup]="editUserForm" (keydown.enter)="handleEnterKey(editUserForm)">
            <div class="mb-5">
                <label for="name1" class="block text-900 font-medium mb-2">{{'LABELS.name' | translate}}</label>
                <input id="name1" type="text" placeholder="{{'LABELS.name' | translate}}" pInputText formControlName="name" class="w-full md:w-30rem" pAutoFocus [autofocus]="true" [tabIndex]="1"/>
                <small *ngIf="editUserForm.touched && editUserForm.dirty && !editUserForm.valid" id="name1-help" class="block w-full p-error">
                    <span *ngIf="name?.errors?.['required']">
                        {{'VALIDATION.name-required' | translate}}
                    </span>
                </small>
            </div>
            <div class="mb-5">
                <label for="lastName1" class="block text-900 font-medium mb-2">{{'LABELS.lastname' | translate}}</label>
                <input id="lastName1" type="text" placeholder="{{'LABELS.lastname' | translate}}" pInputText formControlName="lastName" class="w-full md:w-30rem" [tabIndex]="2"/>
                <small *ngIf="editUserForm.touched && editUserForm.dirty && !editUserForm.valid" id="lastName1-help" class="block w-full p-error">
                    <span *ngIf="lastName?.errors?.['required']">
                       {{ 'VALIDATION.lastname-required' | translate }}
                    </span>
                </small>
            </div>
        </form>
        
        <div class="w-full">
            <p-messages></p-messages>
        </div>
    </div>
</div>
<div class="flex flex-row-reverse gap-2">
    <p-button label="{{'LABELS.save' | translate}}" [outlined]="true" (onClick)="editUser()" [loading]="loading" [disabled]="!editUserForm.dirty || !editUserForm.valid"></p-button>
    <p-button label="{{'LABELS.back' | translate}}" [outlined]="true" severity="secondary" (onClick)="cancel()"></p-button>
</div>