<div class="relative">
    <div *ngIf="loading" class="absolute top-0 left-0 w-full h-full bg-white-alpha-60 z-5">
        <div class="flex align-items-center w-full h-full">
            <p-progressSpinner class="m-auto" styleClass="w-4rem h-4rem alpha-spinner block" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
        </div>
    </div>

    <span class="text-xl font-semibold">{{'LABELS.change-password' | translate}}</span>
    <p-divider></p-divider>
    <div class="mt-5" pFocusTrap>
        <form [formGroup]="changePasswordForm" (keydown.enter)="handleEnterKey(changePasswordForm)">
            <div class="mb-5">
                <label for="oldPassword1" class="block text-900 font-medium mb-2">{{'LABELS.current-password' | translate}}</label>
                <p-password inputId="oldPassword1" placeholder="{{'LABELS.current-password' | translate}}" formControlName="oldPassword" [feedback]="false" [toggleMask]="true" styleClass="w-full md:w-30rem" inputStyleClass="w-full p-3 md:w-30rem" pAutoFocus [autofocus]="true" [tabIndex]="1"></p-password>
                <small *ngIf="changePasswordForm.touched && changePasswordForm.dirty && !changePasswordForm.valid" id="oldPassword1-help" class="block w-full p-error">
                    <span *ngIf="oldPassword?.errors?.['required']">
                        {{'VALIDATION.current-password-required' | translate}}
                    </span>
                    <span *ngIf="oldPassword?.errors?.['minlength']">
                        {{'VALIDATION.current-password-length' | translate}}
                    </span>
                </small>
            </div>
            <div class="mb-5">
                <label for="newPassword1" class="block text-900 font-medium mb-2">{{'LABELS.new-password' | translate}}</label>
                <p-password inputId="newPassword1" placeholder="{{'LABELS.new-password' | translate}}" formControlName="newPassword" [feedback]="true" [toggleMask]="true" styleClass="w-full md:w-30rem" inputStyleClass="w-full p-3 md:w-30rem" [tabIndex]="2"></p-password>
                <small *ngIf="changePasswordForm.touched && changePasswordForm.dirty && !changePasswordForm.valid" id="newPassword1-help" class="block w-full p-error">
                    <span *ngIf="newPassword?.errors?.['required']">
                        {{'VALIDATION.new-password-required' | translate}}
                    </span>
                    <span *ngIf="newPassword?.errors?.['minlength']">
                        {{'VALIDATION.new-password-length' | translate}}
                    </span>
                </small>
            </div>
            <div class="mb-5">
                <label for="confirmPassword1" class="block text-900 font-medium mb-2">{{'LABELS.confirm-password' | translate}}</label>
                <p-password inputId="confirmPassword1" placeholder="{{'LABELS.confirm-password' | translate}}" formControlName="confirmPassword" [feedback]="false" [toggleMask]="true" styleClass="w-full md:w-30rem" inputStyleClass="w-full p-3 md:w-30rem" [tabIndex]="3"></p-password>
                <small *ngIf="changePasswordForm.touched && changePasswordForm.dirty && !changePasswordForm.valid" id="confirmPassword1-help" class="block w-full p-error">
                    <span *ngIf="confirmPassword?.errors?.['required']">
                        {{'VALIDATION.new-password-confirm-required' | translate}}
                    </span>
                    <span *ngIf="confirmPassword?.errors?.['minlength']">
                        {{'VALIDATION.new-password-confirm-length' | translate}}
                    </span>
                    <span *ngIf="confirmPassword?.errors?.['mustMatch']">
                        {{'VALIDATION.password-dont-match' | translate}}
                    </span>
                </small>
            </div>
        </form>
        
        <div class="w-full">
            <p-messages></p-messages>
        </div>
    </div>
</div>
<div class="flex flex-row-reverse gap-2">
    <p-button label="{{'LABELS.save' | translate}}" [outlined]="true" (onClick)="changePassword()" [loading]="loading" [disabled]="!changePasswordForm.touched || !changePasswordForm.dirty || !changePasswordForm.valid"></p-button>
    <p-button label="{{'LABELS.back' | translate}}" [outlined]="true" severity="secondary" (onClick)="back()"></p-button>
</div>