import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { ProfileService } from '../service/api/profile.service';
import { JwtService } from '../service/utils/jwt.service';
import { NGXLogger } from 'ngx-logger';

export const userHomeGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const jwtService = inject(JwtService);
  const profileService = inject(ProfileService);
  const logger = inject(NGXLogger);
  const userRole = jwtService.getUserRole();
  if (userRole != null) {
    if (['organization-admin', 'organization-user'].includes(userRole)) {
      profileService.profile().subscribe({
        next: (response) => {
          if (response.data?.organizations != null && response.data.organizations[0] != null) {
            router.navigate(['organizations', response.data.organizations[0].id, 'detail']);
          }
        },
        error: (errorResponse) => {
          logger.error(errorResponse);
        }
      });
    } else if (userRole === 'cluster-admin') {
      profileService.profile().subscribe({
        next: (response) => {
          if (response.data?.cluster != null) {
            router.navigate(['clusters', response.data.cluster.id, 'detail']);
          }
        },
        error: (errorResponse) => {
          logger.error(errorResponse);
        }
      });
    } else {
      return true;
    }
  } 

  return false;
};
