import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom } from 'rxjs';
import { User } from 'src/app/model/user.model';
import { AuthService } from 'src/app/service/api/auth.service';
import { ProfileService } from 'src/app/service/api/profile.service';
import { EventQueueService } from 'src/app/service/utils/event-queue.service';
import { JwtService } from 'src/app/service/utils/jwt.service';
import { ToastService } from 'src/app/service/utils/toast.service';

enum UserProfilePage {
  Home = 1,
  Edit,
  EditProfilePicture,
  ChangePassword,
  Enable2FA,
  Verify2FAEmail,
  Verify2FATOTP,
  ShowTOTPQRCode,
  Change2FAType
}

export function PasswordMatchValidator(controlName1: string, controlName2: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const control1 = control.get(controlName1);
    const control2 = control.get(controlName2);

    if (control1?.value !== control2?.value) {
      control2?.setErrors({ mustMatch: true });
      return { mustMatch: true };
    } else {
      control2?.setErrors(null);
      return null;
    }
  }
}

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css'],
  providers: [ ConfirmationService, MessageService, TranslateService, DialogService]
})
export class UserProfileComponent implements OnDestroy {
  UserProfilePage = UserProfilePage;
  currentPage = UserProfilePage.Home;
  loading = false;
  user?: User | null = null;
  profileImage?: string;
  qrCodeTOTP?: string;

  ref: DynamicDialogRef | undefined;

  constructor(
    private logger: NGXLogger,
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private dialogService: DialogService,
    private router: Router,
    private jwtService: JwtService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private authService: AuthService,
    private userProfileService: ProfileService,
    private eventQueueService: EventQueueService,
    private toastService: ToastService,
    public translate: TranslateService
  ) {
    this.user = this.dialogConfig.data;
  }

  ngOnDestroy(): void {
    if (this.qrCodeTOTP) {
      URL.revokeObjectURL(this.qrCodeTOTP);
    }
  }

  // UI functions
  showHomePage() {
    this.currentPage = UserProfilePage.Home;
  }

  async showEditPage() {
   this.currentPage = UserProfilePage.Edit
  }

  async showEditProfilePicture() {
    this.currentPage = UserProfilePage.EditProfilePicture;
  }

  async showChangePassword() {
    this.currentPage = UserProfilePage.ChangePassword;
  }

  async showEnable2FA() {
    this.currentPage = UserProfilePage.Enable2FA;
  }

  async showChange2FAType() {    
    this.currentPage = UserProfilePage.Change2FAType;
  }

  async showQRCode() {
    this.currentPage = UserProfilePage.ShowTOTPQRCode;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onEditProfileSave(user: User | null) {
    this.user = user;
    this.showHomePage();
  }

  onEditProfileCancel() {
    this.showHomePage();
  }

  onEdit2FASave(user: User | null) {
    this.user = user;
    this.showHomePage();
  }

  onEdit2FACancel() {
    this.showHomePage();
  }

  onProfilePictureSave(user: User | null) {
    this.user = user;
    this.showHomePage();
  }

  onProfilePictureCancel() {
    this.showHomePage();
  }

  onPasswordChangeSave() {
    this.showHomePage();
  }

  onPasswordChangeCancel() {
    this.showHomePage();
  }

  onQrCodeCancel() {
    this.showHomePage();
  }

  logout() {
    this.jwtService.removeAccessToken();
    this.jwtService.removeRefreshToken();
    this.dialogRef.close();
    this.router.navigate(['auth/login']);
  }

  async sendEmailVerificationMessage() {
    this.loading = true;
    try {
      const response = await lastValueFrom(this.authService.sendEmailVerificationMessage());
      this.messageService.clear();
      this.messageService.add({severity: 'success', summary: 'Email sent'});
    } catch(e) {
      this.messageService.clear();
      this.messageService.add({severity: 'error', summary: 'Error sending email verification message'});
    }
    this.loading = false;
  }

  disable2FA() {
    this.confirmationService.confirm({
      header: this.translate.instant('MESSAGES.disable-2FA-header'),
      message: this.translate.instant('MESSAGES.disable-2FA-confirm-message'),
      icon: 'pi pi-exclamation-triangle',
      acceptIcon:"none",
      rejectIcon:"none",
      rejectButtonStyleClass:"p-button-text",
      accept: async () => {
        this.loading = true;
        try {
          const disableTwoFactorAuthenticationResponse = await lastValueFrom(this.userProfileService.disable2FA());
          this.user!.mfa_enabled = false;
          this.toastService.showSuccess(this.translate.instant('MESSAGES.2fa-disabled'));
        } catch (e) {
          this.messageService.clear();
          if (e instanceof HttpErrorResponse) {
            if (e.status === 401) {
              this.messageService.add({severity: 'error', summary: this.translate.instant('ERRORS.unauthorized')});
            } else if (e.status === 422) {
              this.messageService.add({severity: 'error', summary: this.translate.instant('ERRORS.input-validation-error')});
            }
            this.logger.error('[EditUserProfile] Error disabling two factor authentication: ' + JSON.stringify(e));
          }
        }
        this.loading = false;
      },
      reject: () => {
      }
    });
  }

  async getProfilePicture() {
    this.loading = true;
    if (this.profileImage) {
      URL.revokeObjectURL(this.profileImage);
      this.profileImage = undefined;
    }
    try {
      const userProfileResponse = await lastValueFrom(this.userProfileService.getProfileImage());
      this.profileImage = URL.createObjectURL(userProfileResponse);
    } catch (e) { 
      this.messageService.clear();
      if (e instanceof HttpErrorResponse) {
        if (e.status === 401) {
          this.messageService.add({severity: 'error', summary: this.translate.instant('ERRORS.unauthorized')});
        } else if (e.status === 422) {
          this.messageService.add({severity: 'error', summary: this.translate.instant('ERRORS.input-validation-error')});
        }
        this.logger.error('[EditUserProfile] Error getting profile picture : ' + JSON.stringify(e));
      }
    }
    this.loading = false;
  }

  async getQRCode() {
    this.loading = true;
    if (this.qrCodeTOTP) {
      URL.revokeObjectURL(this.qrCodeTOTP);
      this.qrCodeTOTP = undefined;
    }
    try {
      const qrCodeResponse = await lastValueFrom(this.userProfileService.getQRCodeTOTP());
      this.qrCodeTOTP = URL.createObjectURL(qrCodeResponse);
    } catch (e) { 
      this.messageService.clear();
      if (e instanceof HttpErrorResponse) {
        if (e.status === 401) {
          this.messageService.add({severity: 'error', summary: this.translate.instant('ERRORS.unauthorized')});
        } else if (e.status === 422) {
          this.messageService.add({severity: 'error', summary: this.translate.instant('ERRORS.input-validation-error')});
        }
        this.logger.error(`[${this.constructor.name}] Error getting qrcode image : ` + JSON.stringify(e));
      }
    }
    this.loading = false;
  }

}