import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputTextModule } from 'primeng/inputtext';
import { SidebarModule } from 'primeng/sidebar';
import { BadgeModule } from 'primeng/badge';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MenuModule } from 'primeng/menu';
import { RippleModule } from 'primeng/ripple';
import { AppMenuComponent } from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';
import { RouterModule } from '@angular/router';
import { AppTopBarComponent } from './app.topbar.component';
import { AppFooterComponent } from './app.footer.component';
import { AppConfigModule } from './config/config.module';
import { AppSidebarComponent } from "./app.sidebar.component";
import { AppLayoutComponent } from "./app.layout.component";
import { UserProfileComponent } from '../pages/user-profile/user-profile.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { AvatarModule } from 'primeng/avatar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { UserProfileHeaderComponent } from '../pages/user-profile/user-profile-header.component';
import { TimesIcon } from 'primeng/icons/times';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { TagModule } from 'primeng/tag';
import { PasswordModule } from 'primeng/password';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { AutoFocusModule } from 'primeng/autofocus';
import { FocusTrapModule } from 'primeng/focustrap';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ImageModule } from 'primeng/image';
import { InputMaskModule } from 'primeng/inputmask';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FileUploadModule } from 'primeng/fileupload';
import { TranslateModule } from '@ngx-translate/core';
import { DashboardComponent } from '../pages/dashboard/dashboard.component';
import { OrganizationDashboardComponent } from '../pages/dashboard/organization-dashboard/organization-dashboard.component';
import { AdminDashboardComponent } from '../pages/dashboard/admin-dashboard/admin-dashboard.component';
import { CardModule } from 'primeng/card';
import { InputOtpModule } from 'primeng/inputotp';
import { EditUserDetailsComponent } from '../pages/user-profile/edit-user-details/edit-user-details.component';
import { EditProfilePictureComponent } from '../pages/user-profile/edit-profile-picture/edit-profile-picture.component';
import { ChangePasswordComponent } from '../pages/user-profile/change-password/change-password.component';
import { QRCodeComponent } from '../pages/user-profile/qrcode/qrcode.component';
import { EditTwoFactorAuthenticationComponent } from '../pages/user-profile/edit-two-factor-authentication/edit-two-factor-authentication.component';
import { ClusterDashboardComponent } from '../pages/dashboard/cluster-dashboard/cluster-dashboard.component';

@NgModule({
    declarations: [
        AppMenuitemComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppMenuComponent,
        AppSidebarComponent,
        AppLayoutComponent,
        UserProfileComponent,
        EditUserDetailsComponent,
        EditProfilePictureComponent,
        ChangePasswordComponent,
        QRCodeComponent,
        EditTwoFactorAuthenticationComponent,
        DashboardComponent,
        ClusterDashboardComponent,
        OrganizationDashboardComponent,
        AdminDashboardComponent
    ],
    exports: [
        AppLayoutComponent
    ],
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ButtonModule,
        InputTextModule,
        SidebarModule,
        AvatarModule,
        BadgeModule,
        CardModule,
        RadioButtonModule,
        InputSwitchModule,
        RippleModule,
        RouterModule,
        AppConfigModule,
        MenuModule,
        DynamicDialogModule,
        ProgressSpinnerModule,
        TimesIcon,
        DividerModule,
        TagModule,
        ReactiveFormsModule,
        PasswordModule,
        MessagesModule,
        MessageModule,
        AutoFocusModule,
        FocusTrapModule,
        ConfirmDialogModule,
        ImageModule,
        InputMaskModule,
        ImageCropperModule,
        FileUploadModule,
        TranslateModule,
        InputOtpModule
    ],
    providers: [
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppLayoutModule { }
