import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotfoundComponent } from './pages/notfound/notfound.component';
import { AppLayoutComponent } from './layout/app.layout.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { tokenGuard } from './guards/token.guard';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { clearToastsGuard } from './guards/clear-toasts.guard';
import { userHomeGuard } from './guards/user-home.guard';

const routes: Routes = [
  {
    path: '', component: AppLayoutComponent,
    children: [
      { path: '', component: DashboardComponent, pathMatch: 'full', canActivate: [userHomeGuard] },
      { path: 'users', loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule) },
      { path: 'clusters', loadChildren: () => import('./pages/cluster/cluster.module').then(m => m.ClusterModule) },
      { path: 'organizations', loadChildren: () => import('./pages/organization/organization.module').then(m => m.OrganizationModule) },
      { path: 'assets', loadChildren: () => import('./pages/assets/assets.module').then(m => m.AssetsModule) },
      { path: 'security-standards', loadChildren: () => import('./pages/security-standards/security-standards.module').then(m => m.SecurityStandardsModule) },

    ],
    canActivate: [tokenGuard]
  },
  { path: 'auth', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule), canActivate: [clearToastsGuard] },
  { path: 'unauthorized', component: UnauthorizedComponent, canActivate: [clearToastsGuard] },
  { path: 'notfound', component: NotfoundComponent, canActivate: [clearToastsGuard] },
  { path: '**', redirectTo: '/notfound' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
