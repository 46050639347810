<div class="relative">
    <div *ngIf="loading" class="absolute top-0 left-0 w-full h-full bg-white-alpha-60 z-5">
        <div class="flex align-items-center w-full h-full">
            <p-progressSpinner class="m-auto" styleClass="w-4rem h-4rem alpha-spinner block" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
        </div>
    </div>

    <span class="text-xl font-semibold">{{'LABELS.edit-profile-picture' | translate}}</span>
    <p-divider></p-divider>
    <div class="mt-5" pFocusTrap>
        <div>
            <p-fileUpload 
                chooseLabel="{{'LABELS.choose-image-file' | translate}}"
                accept=".png,.jpg, .jpeg" [showUploadButton]="false"
                [showCancelButton]="false" [customUpload]="true"
                (onSelect)="onProfileImageFileChange($event)"
                (onRemove)="onProfileImageFileChange($event)"
                
            >
            </p-fileUpload>
        </div>

        <div *ngIf="editProfileImageFile">
            <div class="mt-5 mb-2">
                <span class="text-900 text-xl font-medium">{{'LABELS.preview' | translate}}</span>
            </div>
            <div class="border-1 border-200 border-round p-3">
                <image-cropper 
                    [imageFile]="editProfileImageFile"
                    [maintainAspectRatio]="true" 
                    [aspectRatio]="4 / 4"
                    [resizeToWidth]="256" 
                    format="jpeg" 
                    (imageCropped)="cropImg($event)"
                >
                </image-cropper>
            </div>
        </div>
        
        <div class="w-full">
            <p-messages></p-messages>
        </div>
    </div>
</div>
<div class="flex flex-row-reverse gap-2 mt-4">
    <p-button label="{{'LABELS.save' | translate}}" [outlined]="true" (onClick)="editProfilePicture()" [loading]="loading" [disabled]="!editProfileImageFile"></p-button>
    <p-button label="{{'LABELS.back' | translate}}" [outlined]="true" severity="secondary" (onClick)="back()"></p-button>
</div>